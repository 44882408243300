/* ticket_autogestion.css */

/* Estilos generales */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    overflow: auto;
    font-family: Arial, sans-serif;
}

/* Contenedor principal */
.container {
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;
    margin: 0 auto;
    padding: 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

/* Título principal */
.container h1 {
    text-align: center;
    margin-bottom: 20px;
}

/* Contenedor de contenido principal */
.content-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

/* Sección de información del pedido */
.order-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

/* Estilo para la información del cliente */
.order-info div p {
    margin: 5px 0;
}

/* Contenedor para los campos de cotización y moneda */
.exchange-rate-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.exchange-rate-input,
.currency-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100px;
    font-size: 0.9rem;
    margin-left: 10px;
    margin-top: 0;
}

.exchange-rate-input {
    margin-left: 0;
    margin-right: 10px;
}

/* Inputs de la tabla */
.order-info input[type="text"],
.order-info input[type="number"] {
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

/* Contenedor de la tabla y el teclado */
.table-keyboard-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    flex: 1;
}

/* Contenedor de la tabla */
.table-container {
    width: 60%;
    margin-right: 20px;
    overflow-x: auto;
}

.order-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
}

.order-table th,
.order-table td {
    padding: 15px;
    border: 1px solid #e0e0e0;
}

.order-table th {
    background-color: #f7f7f7;
    text-align: left;
}

/* Estilo para el botón en la tabla */
.order-table button {
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    background-color: #6c757d; /* Gris oscuro por defecto */
    color: #fff;
}

.order-table button:hover {
    opacity: 0.8;
}

.order-table tr {
    transition: background-color 0.3s ease;
}

.order-table tr.selected {
    background-color: #d4edda; /* Verde claro para filas seleccionadas */
}

/* Clases adicionales para botones */
.order-table button.selected {
    background-color: #28a745; /* Verde cuando está seleccionado */
}

/* Contenedor del teclado virtual */
.keyboard-container {
    width: 40%;
    margin-top: 20px;
}

/* Estilo para las acciones */
.actions {
    margin-top: 20px;
}

.actions input[type="number"] {
    width: 60px;
    padding: 8px;
    font-size: 1rem;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.actions button {
    padding: 10px 20px;
    font-size: 1rem;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
}

.actions button:hover {
    background-color: #2980b9;
}

/* Estilo para el ticket emergente */
#ticket {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    overflow-y: auto;
    z-index: 1000;
}

#ticket-content {
    background: #fff;
    margin: 50px auto;
    padding: 30px;
    max-width: 600px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

/* Responsivo */
@media (max-width: 800px) {
    .content-container {
        flex-direction: column;
        align-items: stretch;
    }

    .order-info,
    .table-container,
    .keyboard-container {
        width: 100%;
        margin-right: 0;
    }

    .table-keyboard-wrapper {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .actions {
        flex-direction: column;
        align-items: stretch;
    }

    .actions input[type="number"],
    .actions button {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Reglas de impresión */
@media print {
    body * {
        visibility: hidden;
    }
    #ticket, #ticket * {
        visibility: visible;
    }
    #ticket {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: #fff !important;
        height: auto;
    }
    #ticket-content {
        margin: 0;
        padding: 20px;
        max-width: 100%;
        box-shadow: none;
    }
    #ticket button {
        display: none !important;
    }
}

/* Estilo para el botón de "Marcar como Pagada" */
.order-table button.mark-as-paid {
    background-color: #ffc107; /* Amarillo para pendientes */
    color: #fff;
    padding: 5px 10px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.order-table button.mark-as-paid.paid {
    background-color: #28a745; /* Verde para pagadas */
}

.order-table button.mark-as-paid:hover {
    opacity: 0.8;
}

/* Botón de "Marcar como Pagada" */
.order-table button.toggle-payment {
    background-color: #ffc107; /* Amarillo para pendientes */
    color: #fff;
    padding: 5px 10px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

/* Botón cuando está marcado como pagado */
.order-table button.toggle-payment.paid {
    background-color: #28a745; /* Verde para pagadas */
}

.order-table button.toggle-payment:hover {
    opacity: 0.8;
}

.total-payment-container {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745; /* Verde */
  }
  
  .file-upload-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .file-upload-container label {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .file-upload-container input[type="file"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  