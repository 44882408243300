/* Estilos para la hoja de órdenes de retiro - Modernización */
#principalor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

/* Filtros fijos en la parte superior */
#principalor > div.filters-container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  z-index: 1000;
  border-radius: 12px;
}

.filter-input {
  padding: 0.75rem;
  border: 1px solid #17a2b8;
  border-radius: 12px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-input:focus {
  border-color: #42dcf4;
  box-shadow: 0 0 10px rgba(23, 162, 184, 0.3);
}

/* Estilo de los grupos de órdenes */
.group-header {
  margin: 0;
  padding: 1rem;
  background-color: #02c4e2;
  color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.group-header:hover {
  background-color: #0483b2;
}

/* Lista de órdenes */
#principalor .orders-container {
  flex: 1;
  width: 100%;
  max-width: 80vw;
  overflow-y: auto;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Estilo de los botones de órdenes individuales */
.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.5rem;
  margin: 5px 0;
  background-color: #17a2b8;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
}

.order-item:hover {
  background-color: #83c9fe;
}

/* Mensaje de pago no realizado */
.payment-not-paid {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}

/* Ventana emergente de detalles de la orden - Estilo tipo factura moderna */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.order-popup {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #17a2b8;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.order-popup h2 {
  margin-top: 0;
  color: #17a2b8;
  font-size: 1.8rem;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #17a2b8;
  padding-bottom: 1rem;
}

.popup-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #17a2b8;
}

.popup-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #17a2b8;
}

.order-info span.label {
  font-weight: bold;
  color: #17a2b8;
}

.order-info span.value {
  color: #333;
}

.close-button {
  align-self: flex-end;
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  color: #17a2b8;
  border: 1px solid #17a2b8;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #e9ecef;
}

/* Ordenes asociadas dentro del popup */
.associated-order {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.associated-order p {
  margin: 5px 0;
}

/* Estilo de los desplegables */
.order-list {
  background-color: #e3f2fd;
  border: 1px solid #17a2b8;
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1rem;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.order-list.collapsed {
  max-height: 0;
  overflow: hidden;
}

.order-list.expanded {
  max-height: 800px;
  overflow: visible;
}

/* Estilos para el indicador de carga */
.loading {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #555;
}

/* Mensaje de no hay órdenes */
.no-orders {
  padding: 20px;
  text-align: center;
  color: #777;
}
/* Estilos para las órdenes asociadas */

.order-popup ul {
  list-style-type: none;
  padding: 0;
}

.order-popup li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Opcional: Estilos para órdenes pagadas */
.payment-paid {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}

/* Responsividad */
@media (max-width: 600px) {
  .order-popup {
    width: 95%;
    padding: 1.5rem;
  }
  
  .group-header {
    padding: 0.75rem;
  }
  
  .order-item {
    padding: 0.5rem 1rem;
  }
}

/* Estilo para la impresión */
@media print {
  body {
    background-color: #fff;
    color: #000;
    font-family: 'Roboto', sans-serif;
  }
  .overlay {
    display: block;
    position: relative;
    background: none;
    box-shadow: none;
  }
  .close-button, .order-popup button {
    display: none;
  }
  .order-popup {
    width: 100%;
    max-width: none;
    box-shadow: none;
    border: none;
    padding: 1rem;
    position: relative;
    overflow: visible;
    visibility: visible;
  }
  .order-popup h2, .popup-header, .popup-body, .order-info, .associated-order {
    color: #000;
  }
  .order-popup ul {
    padding: 0;
  }
  .order-popup li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #000;
  }
  .order-popup h3 {
    color: #000;
  }
  .order-popup, .order-popup * {
    visibility: visible;
  }
}

.filter-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.filter-button:hover {
  background-color: #0056b3;
}
