/* Ventana_de_ticket.css */

/* Overlay para la ventana modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
  }
  
  /* Contenedor del modal */
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease;
  }
  
  /* Animación para la aparición del modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -55%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  /* Estilos para la tabla del ticket dentro del modal */
  #TiketDeImpresionModal table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  /* Encabezado de la tabla */
  #TiketDeImpresionModal thead {
    background-color: #f5f5f5;
  }
  
  #TiketDeImpresionModal th,
  #TiketDeImpresionModal td {
    padding: 12px 15px;
    text-align: left;
    border: none; /* Sin bordes */
  }
  
  /* Filas de la tabla con fondo alternado para un look moderno */
  #TiketDeImpresionModal tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  #TiketDeImpresionModal tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  /* Efecto hover para resaltar la fila */
  #TiketDeImpresionModal tbody tr:hover {
    background-color: #e9f7ef;
  }
  
  /* Centrado de los botones dentro del modal */
  .ticket-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  /* Durante impresión se ocultan los elementos no deseados */
  @media print {
    .no-print {
      display: none !important;
    }
  }
  