/* pedido_de_stock.css */
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    background: linear-gradient(to right, #f0f0f0, #e6e6e6);
    overflow: hidden;
  }
  .pds-button-container {
    text-align: center;
  }
  button {
    padding: 15px 30px;
    margin: 15px;
    font-size: 1.2em;
    border: none;
    border-radius: 30px;
    background: #007bff;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  button:hover {
    background: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  .pds-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
    overflow: hidden;
  }
  .pds-modal-content {
    background: #ffffff;
    padding: 40px;
    border-radius: 20px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.4s ease-in-out;
    overflow: auto;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  .pds-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5em;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;
  }
  .pds-close:hover {
    color: #000;
  }
  input[type="number"] {
    width: 60px;
    margin: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  input[type="number"]:focus {
    border-color: #007bff;
  }
  .pds-variant-row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pds-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background: #fafafa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
  }
  .pds-short-items-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  .pds-add-short-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .pds-add-short-button {
    padding: 5px 15px;
    font-size: 0.9em;
    border: none;
    border-radius: 20px;
    background: #6c757d;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .pds-add-short-button:hover {
    background: #5a6268;
    transform: translateY(-1px);
  }
  .pds-order-number {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  