/* precios_de_productos.css */
:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --background-color: #ecf0f1;
    --card-background: #ffffff;
    --text-color: #34495e;
    --border-radius: 12px;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
}

.precios-container {
    background-color: var(--card-background);
    border-radius: var(--border-radius);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 900px;
}

.precios-container h1 {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 30px;
}

.precios-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

.precios-container input, .precios-container select, .precios-container textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    font-size: 16px;
    transition: all 0.3s ease;
}

.precios-container input:focus, .precios-container select:focus, .precios-container textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.precios-container .cost-container {
    display: flex;
    gap: 10px;
}

.precios-container .cost-container input {
    flex-grow: 1;
}

.precios-container button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 12px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

.precios-container button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.precios-container .modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.precios-container .modal-content {
    background-color: var(--card-background);
    padding: 20px;
    border-radius: var(--border-radius);
    width: 80%;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.precios-container .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.precios-container .close {
    color: #aaa;
    float: right;
    font-size: 24px;
    font-weight: bold;
    z-index: 1001;
}

.precios-container .close:hover,
.precios-container .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.precios-container .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.precios-container .product-item {
    background-color: var(--card-background);
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.precios-container .product-item input, .precios-container .product-item select, .precios-container .product-item textarea {
    width: 100%;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
}

.precios-container .edit-buttons {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    width: 100%;
}

.precios-container .edit-buttons button {
    flex: 1;
    padding: 6px;
    font-size: 12px;
}

.precios-container .edit-button {
    background-color: var(--secondary-color);
}

.precios-container .edit-button:hover {
    background-color: #27ae60;
}

.precios-container .save-button {
    background-color: var(--primary-color);
}

.precios-container .save-button:hover {
    background-color: #2980b9;
}

.precios-container .delete-button {
    background-color: #e74c3c;
}

.precios-container .delete-button:hover {
    background-color: #c0392b;
}

.precios-container .filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.precios-container .filter-field {
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    font-size: 14px;
    width: 150px;
}