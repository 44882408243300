.PDR-principal {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
}

.PDR {
    flex: 1;
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.PDR1 {
    border-right: none;
}

.PDR2 {
    border-left: none;
}

h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 80vh;
    padding-right: 10px;
}

.order-item {
    background-color: #fffcf2; /* Color más cálido para orden ingresada */
    border: 2px solid #ffcc00; /* Borde de color dorado */
    border-radius: 12px; /* Bordes redondeados */
    padding: 20px; /* Más padding para hacerla más grande */
    margin-bottom: 20px; /* Separación entre órdenes */
    font-size: 2em; /* Aumentar el tamaño de texto */
    color: #000; /* Cambiar texto a negro */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave para darle más enfoque */
    transition: transform 0.2s ease-in-out; /* Animación para hover */
    height: 120px; /* Ajustar alto para ser más rectangulares */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold; /* Negrita */
    display: flex; /* Flex para que los elementos puedan estar alineados fácilmente */
     flex-direction: column; /* Establece una columna para los elementos dentro */
}

.order-item:hover {
    transform: scale(1.05); /* Aumentar ligeramente el tamaño al pasar el mouse */
}

.order-item.empaquetada {
    background-color: #e0f7e0; /* Verde claro para orden empaquetada */
    border-color: #66bb6a; /* Borde verde */
    color: #000; /* Texto en negro */
}

.no-orders {
    color: #999;
    font-size: 1.2em;
    text-align: center;
    margin-top: 20px;
}

/* Scrollbar styling */
.scrollable-content::-webkit-scrollbar {
    width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.payment-message {
    color: #d9534f;
    font-weight: bold;
    font-size: 0.8em; /* Reduce el tamaño del texto */
    margin-top: 5px; /* Espacio entre la orden y el mensaje */
    display: block; /* Asegura que el mensaje esté en un renglón nuevo */
    text-align: center; /* Opcional: centra el texto dentro del cuadro */
  }
  