/* RegisterAdmin.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
  }
  
  .full-screen-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: relative;
  }
  
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .register-box {
    width: 300px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .register-box:hover {
    transform: translateY(-10px);
  }
  
  .register-title {
    margin-bottom: 20px;
    color: #007acc;
    font-size: 24px;
  }
  
  .register-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .register-icon img {
    max-width: 100%;
    height: auto;
  }
  
  .input-group {
    margin-bottom: 15px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .input-group label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
    transition: box-shadow 0.3s ease;
  }
  
  .input-group input:focus {
    border-color: #007acc;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 122, 204, 0.5);
  }
  
  .input-group:hover {
    transform: translateY(-5px);
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007acc;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #005fa3;
    transform: translateY(-5px);
  }
  
  .register-box form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .error {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 500px) {
    .register-box {
      width: 90%;
      padding: 20px;
    }
  }
  