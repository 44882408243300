/* ========================= */
/* Estilos generales         */
/* ========================= */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    color: #000;
  }
  
  .CLIContainer {
    width: 100%;
    height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  .CLIContainer:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .CLIContainer_h2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .CLIContainer_searchInput {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
  }
  
  .CLIContainer_searchInput:focus {
    border-color: #00bcd4;
    box-shadow: 0 0 8px rgba(0, 188, 212, 0.2);
    outline: none;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .CLIContainer_searchButton {
    padding: 8px;
    font-size: 14px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .CLIContainer_searchButton:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  /* Botones para filtrar por tipo de cliente (ahora en blanco, sin borde, tamaño uniforme) */
  .tipo-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .tipo-buttons button {
    padding: 6px 10px;
    font-size: 12px;
    background-color: #fff;
    color: #00bcd4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 80px;
  }
  
  .tipo-buttons button.active,
  .tipo-buttons button:hover {
    background-color: #f0f0f0;
  }
  
  /* ========================= */
  /* Acciones en comprobante (botones dentro de crear comprobante) */
  /* ========================= */
  .comprobante-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0;
    padding: 0;
  }
  
  .comprobante-actions button {
    padding: 4px 8px;
    font-size: 12px;
    background-color: #fff;
    color: #00bcd4;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin: 0;
    width: 80px;
    transition: transform 0.1s ease, background-color 0.2s ease;
  }
  
  .comprobante-actions button:active {
    transform: scale(0.95);
  }
  
  .comprobante-actions button:hover {
    background-color: #f0f0f0;
  }
  
  /* ========================= */
  /* Modal y scrollbars        */
  /* ========================= */
  .clientes-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    z-index: 1000;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  .clientes-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    width: auto;
    min-width: 1200px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    animation: fadeIn 0.5s ease-out;
    align-self: center;
    margin: auto;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .clientes-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #000;
    cursor: pointer;
  }
  
  /* ========================= */
  /* Grid de clientes          */
  /* ========================= */
  .client-grid-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  
  .clients-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .client-card {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    text-align: center;
    box-sizing: border-box;
    overflow-wrap: break-word;
  }
  
  .client-card:hover {
    background-color: #f5f5f5;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  /* ========================= */
  /* Formularios (crear y editar) */
  /* ========================= */
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-container input,
  .form-container select {
    width: 100%;
    padding: 10px;
    margin: 5px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-container input:focus,
  .form-container select:focus {
    border-color: #00bcd4;
    box-shadow: 0 0 8px rgba(0, 188, 212, 0.2);
    outline: none;
  }
  
  .form-container button {
    padding: 12px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .form-container button:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  .edit-form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
  }
  
  .form-group span {
    margin-top: 3px;
    color: #555;
    font-size: 16px;
  }
  
  /* ========================= */
  /* Modal de Perfil de Cliente */
  /* ========================= */
  .perfil-modal-content {
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .perfil-columns-container {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
  }
  
  .perfil-left-column {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .perfil-client-code {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  
  .perfil-info-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .perfil-info-item {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 1px solid #eee;
  }
  
  .perfil-info-item label {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
  .edit-personal-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .edit-personal-button {
    padding: 10px 20px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .edit-personal-button:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  .perfil-right-column {
    width: 800px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* ========================= */
  /* Contenedor de botones en controles */
  /* ========================= */
  .control-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .control-icons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
  }
  
  .control-icons button:hover {
    background-color: #e0e0e0;
  }
  
  /* ========================= */
  /* Contenedor de contenido de control sin borde */
  /* ========================= */
  .control-content {
    border: none;
    border-radius: 8px;
    padding: 15px;
    min-height: 100px;
    font-size: 16px;
    color: #555;
  }
  
  /* ========================= */
  /* Vista de edición centralizada */
  /* ========================= */
  .editing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .editing-container h3 {
    margin-bottom: 20px;
  }
  
  .button-group-modal {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .button-group-modal button {
    padding: 10px 20px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .button-group-modal button:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  /* ========================= */
  /* Estilos para la tabla de órdenes */
  /* ========================= */
  .orders-table-wrapper {
    position: relative;
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
  }
  
  .orders-scroll-top,
  .orders-scroll-bottom {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }
  
  .orders-scroll-top {
    height: 16px;
    margin-bottom: 4px;
  }
  
  .orders-scroll-bottom {
    height: 16px;
    margin-top: 4px;
  }
  
  .orders-table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;
    transition: transform 0.3s ease;
  }
  
  .orders-table thead {
    background-color: #00bcd4;
    color: #fff;
  }
  
  .orders-table th,
  .orders-table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .orders-table tbody tr {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .orders-table tbody tr:hover {
    background-color: #f1f1f1;
    transform: translateX(5px);
  }
  
  /* ========================= */
  /* Estilos para filtros de órdenes */
  /* ========================= */
  .orders-filters {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
  
  .orders-filters input,
  .orders-filters select {
    width: 120px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .orders-filters input:focus,
  .orders-filters select:focus {
    border-color: #00bcd4;
  }
  
  .orders-filters button {
    padding: 5px 10px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .orders-filters button:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  /* ========================= */
  /* Estilos modernos para el formulario de pagos */
  /* ========================= */
  .pagos-form.modern {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .pagos-form.modern .form-group input,
  .pagos-form.modern .form-group select {
    width: 100%;
    height: 40px;
    padding: 8px;
    font-size: 16px;
  }
  
  .pagos-form.modern .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .pagos-form.modern .full-width {
    grid-column: 1 / -1;
  }
  
  .pagos-form.modern .button-group {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
  }
  
  .pagos-form.modern .modern-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #00bcd4;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .pagos-form.modern .modern-button:hover {
    background-color: #0097a7;
    transform: translateY(-2px);
  }
  
  /* ========================= */
  /* Estilo para mostrar el total de pagos */
  /* ========================= */
  .total-payments {
    margin-bottom: 20px;
    font-size: 16px;
    color: green;
    text-align: center;
    font-style: italic;
  }
  
  /* ========================= */
  /* Nuevos estilos para control de deudas */
  /* ========================= */
  .deuda-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .deuda-toggle-buttons {
    display: inline-flex;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
  }
  
  .deuda-toggle-button {
    background-color: white;
    border: none;
    border-right: 1px solid #ccc;
    padding: 4px 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.2s ease;
    text-align: center;
    font-size: 12px;
    color: black;
    font-weight: bold;
    border-radius: 0;
    margin: 0;
    white-space: nowrap;
  }
  
  .deuda-toggle-button.active {
    background-color: #e0e0e0;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
    color: green;
  }
  
  .deuda-toggle-button:last-child {
    border-right: none;
  }
  
  .deuda-tables {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  
  .deuda-table-container {
    width: 100%;
    max-width: 600px;
  }
  
  .deuda-table-container h4 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }
  
  .deuda-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .deuda-table th,
  .deuda-table td {
    padding: 10px;
    text-align: left;
    border: none;
    background-color: transparent;
  }
  
  /* ========================= */
  /* Estilos minimalistas para comprobante */
  /* ========================= */
  .minimal-input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    outline: none;
    transition: all 0.3s ease;
    background-color: transparent;
  }
  
  .minimal-input:focus {
    border-bottom: 2px solid #00bcd4;
    box-shadow: inset 0 -2px 0 #00bcd4;
  }
  
  .minimal-input-group {
    margin-bottom: 15px;
  }
  
  .comprobante-form {
    padding: 20px;
    position: relative;
  }
  
  .comprobante-header p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .comprobante-inputs {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
  
  /* La "tabla" de órdenes se muestra sin estilo de encabezado ni divisiones verticales. 
     Cada fila se separa con una línea horizontal oscura. */
  .comprobante-table table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .comprobante-table th,
  .comprobante-table td {
    border: none;
    padding: 8px;
    font-size: 14px;
  }
  
  .comprobante-table tr {
    border-bottom: 1px solid #333;
  }
  
  /* Totales: Sub total, IVA y Total en filas separadas, alineadas a la derecha */
  .comprobante-totals {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
  }
  
  .comprobante-totals .total-row {
    margin-bottom: 10px;
  }
  
  .comprobante-totals label {
    margin-right: 10px;
    font-size: 14px;
  }
  