/* Contenedor principal fijo */
.fixed-container {
  width: 1200px; /* Ocupa todo el ancho de la pantalla */
  height: 580px; /* Ajusta la altura según sea necesario */
  overflow: hidden; /* Evita que el contenido se desborde fuera del contenedor */
  margin: 50px auto; /* Centra el contenedor verticalmente */
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

/* Contenido desplazable dentro del contenedor */
.scrollable-content {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Permite el desplazamiento vertical del contenido */
  padding: 20px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #b3b3b3 #e0e0e0;
}

.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

/* Código existente actualizado para que funcione dentro del contenedor fijo */
body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e0e0e0;
  color: #333;
  overflow: hidden;
}

.container-edp {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  overflow: hidden;
  background-color: #ffffff;
  text-align: center;
  transition: transform 0.3s ease;
}

.odr-section {
  width: 25%; /* Contenedor 1: ODR - Ajustar ancho */
  background-color: #ffffff;
  padding: 10px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
  scrollbar-width: none;
}

.odr-section .filter-dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.odr-section .filter-dropdown:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.odr-section .filter-dropdown:focus {
  outline: none;
  border-color: #4caf50;
}

.odr-section .filter-dropdown option {
  padding: 10px;
}

.odr-section::-webkit-scrollbar {
  display: none;
}

.odp-section {
  width: 50%; /* Contenedor 2: ODP - Ajustar ancho */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  max-height: 100%;
  text-align: center;
  scrollbar-width: none;
}

.odp-section::-webkit-scrollbar {
  display: none;
}

.esc-section {
  width: 25%; /* Contenedor 3: ESC - Ajustar ancho */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.scanned-values-section {
  display: flex;
  flex-direction: column; /* Cambia la dirección a columna para colocar los campos en fila vertical */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px; /* Espacio entre los campos */
}

.top-right-section {
  position: sticky;
  top: 0;
  height: auto;
  padding: 30px;
  background-color: #fafafa;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: scale(0.8);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.top-right-section.show {
  transform: scale(1);
  opacity: 1;
}

.selected-order {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.client-id {
  font-size: 1.2em;
  color: #777;
  text-align: center;
}

.bottom-right-section {
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
  scrollbar-width: none;
}

.bottom-right-section::-webkit-scrollbar {
  display: none;
}

.bottom-right-section .purchase-orders {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  transition: transform 0.3s ease;
}

.order-button {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease, transform 0.5s ease;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.order-button .order-pickup-location {
  font-weight: bold;
  margin-top: 5px;
}

.order-button:active {
  transform: scale(0.95);
}

.order-button.scanned {
  background-color: #4caf50; /* Orden pintada de verde si coincide */
  color: #ffffff;
}

.order-button.selected {
  animation: appear 0.5s ease;
}

@keyframes appear {
  0% {
      transform: scale(0.8);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

.purchase-order {
  padding: 8px 12px;
  background-color: #eeeeee;
  border-radius: 12px;
  font-size: 0.9em;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
}

.purchase-order:hover {
  background-color: #dddddd;
}

/* Estilo para los campos de entrada pequeños */
.scanned-input {
  width: 100%; /* Ajusta el ancho al 100% para que ocupe toda la fila */
  margin: 5px 0; /* Espacio vertical entre los campos */
  padding: 10px;
  font-size: 1em;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.scanned-input:focus {
  border-color: #4caf50;
  outline: none;
}  

/* Pintar de verde el botón del pedido si coincide con lo escaneado */
.purchase-order.matched {
  background-color: #4caf50;
  color: #ffffff;
}

.order-actions {
  display: flex; /* Alinea los elementos en una fila */
  align-items: center; /* Centra verticalmente los botones y el texto */
  justify-content: center; /* Opcional: Ajusta según prefieras */
  gap: 10px; /* Espaciado entre los elementos */
  margin: 20px 0; /* Ajusta el espaciado con otros elementos */
}

.selected-order {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.button-cancelar,
.button-pronto {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.button-cancelar {
  background-color: #af564c;
  color: #ffffff;
}

.button-pronto {
  background-color: #4caf50;
  color: #ffffff;
}

.button-cancelar:hover,
.button-pronto:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.button-cancelar:active,
.button-pronto:active {
  transform: scale(0.95);
}

