body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f4f8;
    color: #333;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.container {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 100vw;
    min-width: 1000px;
    width: 100%;
    height: auto;
}

h1 {
    font-size: 28px;
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 30px;
    text-align: center;
}

.code-inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.code-input {
    width: 100%;
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.3s ease, transform 0.3s ease;
    background-color: #f9f9f9;
}

.code-input:hover {
    transform: scale(1.02);
}

.code-input:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
    outline: none;
    background-color: #ffffff;
    transform: scale(1.05);
}

.code-input::placeholder {
    color: #bdc3c7;
}

#loadButton {
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease, transform 0.2s ease;
    display: block;
    margin: 30px auto 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#loadButton:hover {
    background-color: #2980b9;
    transform: scale(1.05);
}

#loadButton:active {
    transform: translateY(2px) scale(0.98);
}

.message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 800px) {
    .container {
        padding: 20px;
        width: 95%;
    }
}

@media (max-width: 600px) {
    h1 {
        font-size: 22px;
    }

    #loadButton {
        font-size: 16px;
        padding: 10px 20px;
    }
}
