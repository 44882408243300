/* descuento_y_recargos.css */
/* Archivo CSS mejorado para manejar placeholders y estilos de enfoque */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f2f2f2;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.container {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  animation: fadeInDown 0.5s ease;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  animation: fadeInUp 0.5s ease;
  width: 100%;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  transition: transform 0.3s ease;
}

.form-group:hover {
  transform: translateY(-5px);
}

label {
  font-weight: 600;
  animation: fadeInLeft 0.5s ease;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

input, select {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  animation: scaleIn 0.5s ease;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

input:focus, select:focus {
  border-color: #007bff;
  outline: none;
}

input:focus::placeholder, select:focus::placeholder {
  color: transparent; /* Oculta el placeholder cuando el campo está en foco */
}

input:hover, select:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input:not(:placeholder-shown), select:not(:placeholder-shown) {
  padding: 0.75rem 1rem; /* Ajuste de padding para evitar superposición */
  color: #333;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

button {
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  grid-column: span 2;
  justify-self: center;
  animation: scaleIn 0.5s ease;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
}

.discount-surcharge-card {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: fadeIn 0.5s ease;
  transition: transform 0.3s ease;
  position: relative;
}

.discount-surcharge-card:hover {
  transform: translateY(-5px);
}

.discount-surcharge-card h3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 0.9rem;
}

.discount-surcharge-card p {
  margin: 0;
  font-size: 0.8rem;
}

.delete-btn {
  background-color: #e74c3c;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.delete-btn:hover {
  background-color: #c0392b;
  transform: translateY(-3px);
}