/* ticket_autogestion.css */

/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: auto;
  font-family: Arial, sans-serif;
}

/* Contenedor principal */
.container {
  max-width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

/* Título principal */
.container h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Contenedor de contenido principal */
.content-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Información del pedido */
.order-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 20px;
  padding: 15px;
  background-color: #fff;
}

/* Información del cliente */
.order-info div p {
  margin-bottom: 5px;
  font-size: 1.1rem;
}

/* Campo para ingresar órdenes */
.order-input-field {
  padding: 10px;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Indicador del total de órdenes */
.orders-count {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin: 15px 0;
  color: #00aaff;
}

/* Contenedor de la tabla */
.table-container {
  width: 100%;
  overflow-x: auto;
}

/* Tabla de órdenes */
.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: transparent;
  word-wrap: break-word;
  white-space: normal;
}

.order-table th,
.order-table td {
  padding: 15px 25px;
  border: none;
  font-size: 1.3rem;
  color: #555;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.order-table th {
  background-color: transparent;
  color: #333;
  font-weight: bold;
  text-align: left;
}

/* Botón para seleccionar todas las órdenes */
.select-all-btn {
  font-size: 1.3rem;
  padding: 15px;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Botón de selección individual */
.select-btn {
  font-size: 1.5rem;
  padding: 10px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.select-btn.selected {
  background-color: #28a745;
}

/* Componente para mostrar el input sin borde */
.input-display {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  border: none;
  padding: 5px;
}

/* Modal del teclado */
#keyboard-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-keyboard-btn {
  margin-bottom: 10px;
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

#keyboard-modal .modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

/* Ticket emergente */
#ticket {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#ticket-content {
  background: #fff;
  padding: 30px;
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 10px rgba(0,0,0,0.15);
  max-height: 90vh;
  overflow-y: auto;
}

#ticket-content .ticket-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.ticket-btn {
  font-size: 1.1rem;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.close-ticket-btn {
  background-color: #ff4d4d;
}

.print-ticket-btn {
  background-color: #28a745;
}

.ticket-copy {
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.ticket-copy::after {
  content: "";
  display: none;
}

/* Tabla del ticket */
.ticket-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: transparent;
  word-wrap: break-word;
  white-space: normal;
}

.ticket-table th,
.ticket-table td {
  padding: 15px 25px;
  border: none;
  font-size: 1.3rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.ticket-table th {
  background-color: transparent;
  color: #000000;
  font-weight: bold;
  text-align: left;
}

.ticket-table td {
  color: #000000;
}

.ticket-total p {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 15px;
}

/* --- Estilos Modernos para el Teclado --- */
.simple-keyboard {
  position: relative !important;
  width: 100%;
  background: transparent;
  padding: 10px;
}

.simple-keyboard .hg-row {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}

.simple-keyboard .hg-button {
  flex: 1;
  margin: 5px;
  height: 50px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  color: #000000;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.simple-keyboard .hg-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

/* --- Estilos específicos para el ticket --- */
#ticket-content .client-info,
#ticket-content .withdrawal-order {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

/* --- Reglas para impresión --- */
@page {
  size: auto;
  margin: 0;
}

@media print {
  body * {
    visibility: hidden;
  }
  #ticket, #ticket * {
    visibility: visible;
  }
  #ticket {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff !important;
    height: auto;
    overflow: visible;
    padding: 0;
  }
  #ticket-content {
    margin: 0;
    width: 100%;
    padding: 0;
    max-width: 100%;
    max-height: none;
    box-shadow: none !important;
    border-radius: 0 !important;
    text-align: center;
  }
  /* Cada copia del ticket en página separada */
  .ticket-copy {
    width: 100%;
    max-width: 100%;
    margin: 0;
    page-break-after: always;
    page-break-inside: avoid;
  }
  #ticket-content,
  #ticket-content * {
    font-weight: bold;
  }
  #ticket button,
  .no-print {
    display: none !important;
  }
  /* Configuración para que la tabla ocupe el ancho completo y su contenido se ajuste */
  .ticket-table,
  .order-table {
    margin: 0;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  .ticket-table th,
  .ticket-table td,
  .order-table th,
  .order-table td {
    border: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    font-size: 1rem; /* Fuente más pequeña para evitar desbordes */
  }
  /* Contenedor adicional para centrar la tabla en impresión */
  .table-print-container {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .table-print-container table {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    margin: 0;
  }
  .table-print-container table th,
  .table-print-container table td {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    font-size: 1rem;
  }
  html, body {
    zoom: 1;
  }
}
