/* Login.css */

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.full-screen-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-box {
  width: 400px; /* Aumentar el ancho */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  transition: transform 0.3s ease;
}

.login-box:hover {
  transform: translateY(-5px);
}

.login-title {
  margin-bottom: 20px;
  color: #007acc;
  font-size: 28px;
  font-weight: bold;
}

.login-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.login-icon img {
  max-width: 100%;
  height: auto;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
  transition: transform 0.3s ease;
}

.input-group label {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

.input-group input {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px; /* Tamaño de fuente más grande */
  transition: box-shadow 0.3s ease;
}

.input-group input:focus {
  border-color: #007acc;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 122, 204, 0.5);
}

.input-group:hover {
  transform: translateY(-3px);
}

.login-box form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos */
  gap: 20px;
}

.login-button {
  width: 100%; /* Ancho completo del contenedor */
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007acc;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease;
}

.login-button:hover {
  background-color: #005fa3;
  transform: translateY(-3px);
}

.error {
  color: #d9534f;
  font-size: 14px;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 500px) {
  .login-box {
    width: 90%;
    padding: 30px;
  }

  .login-title {
    font-size: 24px;
  }

  .login-button {
    width: 100%;
  }
}
