/* RecepcionDeEntrega.css */

.CONTRECPRINCIPAL {
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.CONTREC1, .CONTREC2 {
  flex: 1;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden; scrollbar-width: none; -ms-overflow-style: none; ::-webkit-scrollbar { display: none; }
}

.CONTREC1 {
  flex: 0.5; /* Ocupa menos espacio para reducir aún más el tamaño del campo de las órdenes de retiro */
  margin-right: 10px;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; scrollbar-width: none; -ms-overflow-style: none; ::-webkit-scrollbar { display: none; }
}

.CONTREC2 {
  flex: 1; /* Ocupa la mitad de la pantalla */
  margin-left: 0px; /* Mover más a la derecha */
  position: relative;
  padding-top: 60px;
}

.entregado-button {
  margin-top: auto; /* Empuja el botón hacia la parte inferior */
  align-self: flex-end; /* Alinea el botón a la derecha */
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.entregado-button:hover {
  background-color: #218838;
}

.filter-dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.filter-dropdown:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.filter-dropdown:focus {
  outline: none;
  border-color: #4caf50;
}

.order-button {
  width: 80%; /* Reducir el ancho de los campos de las órdenes */
  margin: 0 auto; /* Centrar los botones */
  background-color: #ebecee;
  color: rgb(0, 0, 0);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.order-button:hover {
  background-color: #00a4e0;
}

.order-pickup-location {
  font-weight: lighter;
  font-size: 0.8em;
  margin-top: 5px;
}

.CONTREC2 .order-button {
  background-color: #e9e9e9;
}

.CONTREC2 .order-button:hover {
  background-color: #00a4e0;
}

.no-orders {
  color: #888;
  text-align: center;
  font-style: italic;
}

h2 {
  margin-top: 20px;
  color: #333;
}

.scrollable-content {
  flex-grow: 1;
  overflow: hidden; scrollbar-width: none; -ms-overflow-style: none; ::-webkit-scrollbar { display: none; }
  padding-right: 10px;
}

.CONTREC3.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  min-height: 300px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.close-button:hover {
  color: #333;
}

.purchase-orders {
  margin-top: 20px;
}

.purchase-order {
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

/* Estilos para el botón de impresión específico */
.print-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.print-button:hover {
  background-color: #0056b3;
}
