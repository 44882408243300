/* Navbar.css */

/* Importar fuente Orbitron de Google Fonts */

/* Estilos para la barra de navegación */
nav {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0; /* Siempre visible */
  width: 50px; /* Ancho inicial reducido de la barra de navegación */
  max-height: 100vh; /* Altura completa */
  padding: 0px;
  z-index: 9999; /* Aumentar el z-index para superponer la barra sobre cualquier contenedor */
  transition: width 0.3s ease;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Permitir desplazamiento vertical */
  scrollbar-width: none; /* Ocultar barra de desplazamiento en Firefox */
}

nav.active {
  width: 160px; /* Ancho expandido para mostrar nombres */
}

/* Estilos para el botón de mostrar/ocultar */
#menu-button {
  position: fixed;
  top: 0;
  left: 50px; /* Pegado al borde de la barra de navegación */
  z-index: 10000; /* Aumentar el z-index para que el botón esté siempre visible */
  background-color: transparent;
  color: #3498db;
  border: none;
  padding: 5px;
  font-size: 0.6em;
  cursor: pointer;
  border-radius: 0%;
  outline: none;
  transition: left 0.3s ease;
  height: 100%; /* Ajustar el alto al de la pantalla */
  display: flex;
  align-items: center;
}

/* El botón se mueve junto con la barra de navegación */
#menu-button.active {
  left: 160px; /* Se queda pegado a la barra expandida */
}

/* Flecha que gira según el estado */
#menu-button::before {
  content: '▶'; /* Flecha apuntando a la derecha */
  display: inline-block;
  transition: transform 0.3s ease;
}

#menu-button.active::before {
  transform: rotate(180deg); /* Flecha apunta a la izquierda */
}

#menu-button::before {
  transform-origin: center;
}

/* Estilos para los enlaces del menú */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 80px); /* Ajustar para que el área del login sea fija y el resto pueda deslizar */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none; /* Ocultar barra de desplazamiento en Firefox */
}

nav ul::-webkit-scrollbar {
  display: none; /* Ocultar barra de desplazamiento en Chrome, Safari y Opera */
}

nav ul li {
  width: 100%;
  margin: 10px 0; /* Aumentar el margen vertical para separar los iconos */
  text-align: left;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.link-icon {
  text-decoration: none;
  color: #3498db;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.link-icon img {
  width: 40px; /* Fijar el ancho del icono */
  height: 40px; /* Fijar la altura del icono */
  object-fit: contain; /* Ajustar para mantener la proporción sin deformar */
  margin-right: 10px; /* Espacio entre el icono y el texto */
}

nav.active .link-icon span {
  display: inline; /* Mostrar el texto del enlace cuando la barra está expandida */
}

.link-icon span {
  display: none; /* Ocultar el texto del enlace por defecto */
}

/* Efecto flotante 3D al pasar el cursor */
nav ul li a:hover {
  transform: translateY(-8px);
}

/* Estilos para el enlace de Iniciar Sesión */
.login-link-container {
  padding: 5px;
}

.login-link {
  font-size: 0.5em;
}

.login-link:hover {
  transform: scale(1.1);
}

.login-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.login-text {
  font-weight: bold;
  color: #3498db;
}
