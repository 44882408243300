/* entrega_de_pedido_con_estilos.css */

body {
    font-family: 'Poppins', sans-serif;
    background-color: #f0f0f0;
    color: #333;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* Contenedor Principal (CajaContainer) */
.caja-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(90vw - var(--nav-bar-width, 0px));
    background: #ffffff;
    padding: 20px;
    margin: 0;
    overflow-x: hidden;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    gap: 20px;
    max-width: 1200px; /* Ajuste del tamaño del contenedor */
    transition: width 0.3s ease;
}

/* Contenedor para Agrupar Campos en la Misma Fila */
.caja-field-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

/* Contenedor de Campo y Etiqueta */
.caja-field-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1; /* Permitir que el contenedor crezca para ocupar el espacio disponible */
    width: auto;
}

/* Etiqueta de los Campos */
.caja-label {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 5px;
}

/* Campo para Seleccionar la Forma de Pago */
.caja-payment-method {
    padding: 10px;
    font-size: 0.9em;
    border: 2px solid #ddd;
    border-radius: 50px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    width: 100%;
}

.caja-payment-method:focus {
    border-color: #007acc;
    box-shadow: 0 0 8px rgba(0, 122, 204, 0.3);
    outline: none;
}

/* Campo para Seleccionar la Moneda */
.caja-currency {
    padding: 10px;
    font-size: 0.9em;
    border: 2px solid #ddd;
    border-radius: 50px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    width: 100%;
}

.caja-currency:focus {
    border-color: #007acc;
    box-shadow: 0 0 8px rgba(0, 122, 204, 0.3);
    outline: none;
}

/* Campo para Cargar Comprobante de Pago */
.caja-file-upload {
    padding: 10px;
    font-size: 0.9em;
    border: 2px solid #007acc;
    border-radius: 50px;
    background-color: #007acc;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.caja-file-upload:hover {
    background-color: #005bb5;
    box-shadow: 0 0 8px rgba(0, 91, 181, 0.3);
}

/* Campo para Ingresar el Monto Pagado */
.input-payment-value {
    padding: 10px;
    font-size: 0.9em;
    border: 2px solid #ddd;
    border-radius: 50px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    width: 100%;
}

.input-payment-value:focus {
    border-color: #007acc;
    box-shadow: 0 0 8px rgba(0, 122, 204, 0.3);
    outline: none;
}

/* Contenedor para el Botón de Pago */
.caja-payment-button-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

/* Botón de Pago */
.caja-payment-button {
    padding: 5px 10px;
    font-size: 0.8em;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(to right, #007acc, #005bb5);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.4s, box-shadow 0.4s;
    width: auto;
}

.caja-payment-button:hover {
    background: linear-gradient(to right, #005bb5, #003f7f);
    box-shadow: 0 6px 15px rgba(0, 91, 181, 0.4);
}

/* Contenedor de Órdenes Disponibles */
.caja-order-button {
    background: #ffffff;
    border: 2px solid #007acc;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.4s, border-color 0.4s, box-shadow 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #007acc;
    font-weight: bold;
}

.caja-order-button:hover {
    background-color: #e0f7fa;
    border-color: #005bb5;
    box-shadow: 0 6px 15px rgba(0, 91, 181, 0.3);
}

.caja-order-button.active {
    background-color: #007acc;
    color: #ffffff;
    border-color: #007acc;
    box-shadow: 0 6px 20px rgba(0, 122, 204, 0.3);
}

/* Mensaje de No Hay Órdenes Disponibles */
.caja-no-orders {
    font-size: 1.3em;
    color: #888;
    text-align: center;
}

/* Título de la Orden Seleccionada */
.caja-order-title {
    font-size: 2em;
    font-weight: 700;
    color: #007acc;
    margin-bottom: 15px;
    text-align: center;
}

/* Total a Cobrar */
.caja-total-amount {
    font-size: 1em;
    font-weight: bold;
    color: #007acc;
    margin-bottom: 15px;
    text-align: center;
}

/* Órdenes de Pedido Individuales */
.caja-purchase-order {
    background: #ffffff;
    border: 2px solid #007acc;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 10px;
    transition: box-shadow 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #007acc;
    font-weight: bold;
}

.caja-purchase-order:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border-color: #005bb5;
}

@media (max-width: 768px) {
    .caja-container {
        padding: 10px;
        width: 100vw;
    }

    .caja-container > * {
        width: 100%;
        margin: 10px;
    }

    .caja-field-row {
        flex-direction: column;
    }

    .caja-payment-method,
    .caja-currency,
    .caja-file-upload,
    .input-payment-value {
        width: 100%;
    }

    .caja-order-button,
    .caja-purchase-order {
        width: 100%;
    }
}

.caja-purchase-order.no-pronto {
    color: #a94442;
}

.caja-order-message {
    color: #d9534f;
    font-size: 0.9em;
    font-style: italic;
    display: inline-block;
    margin-left: 10px;
    font-weight: normal;
}

/* Estilo moderno para la cotización del dólar */
.caja-cotizacion {
    background-color: #e3f2fd;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 122, 204, 0.2);
    font-weight: bold;
    color: #005bb5;
    font-size: 1em;
}

.caja-cotizacion h4 {
    margin: 0;
    font-size: 1.1em;
    color: #007acc;
}

.caja-cotizacion p {
    margin: 5px 0 0;
    font-size: 1em;
    color: #333;
}

/* Estilo para la ventana de impresión */
@media print {
    body {
        background-color: #ffffff;
        color: #000;
    }

    .caja-prin,
    .caja-c3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;
    }

    .caja-order-details,
    .caja-total-amount,
    .caja-purchase-orders {
        text-align: center;
        margin: 0 auto;
    }

    .caja-order-title,
    .caja-total-amount,
    .caja-purchase-order {
        color: #000;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .caja-purchase-order {
        border: 1px solid #000;
        padding: 10px;
        margin-bottom: 10px;
    }
}

.input-cotizacion {
    width: 400px !important;/* Ajusta el ancho según sea necesario */
    padding: 10px; /* Espaciado interno */
    font-size: 16px; /* Tamaño de la fuente */
    border: 2px solid #ddd; /* Borde */
    border-radius: 5px; /* Borde redondeado */
    box-sizing: border-box; /* Incluir padding y border en el ancho */
    transition: all 0.3s ease-in-out; /* Animación suave para cambios */
  }
  
  .input-cotizacion:focus {
    border-color: #007acc; /* Cambia el color del borde al enfocarse */
    box-shadow: 0 0 10px rgba(0, 122, 204, 0.5); /* Efecto de sombra */
    outline: none; /* Elimina el borde de enfoque por defecto */
  }
  
  .caja-order-button.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  